import { render, staticRenderFns } from "./GetApplications.vue?vue&type=template&id=2573474a&scoped=true&"
import script from "./GetApplications.vue?vue&type=script&lang=js&"
export * from "./GetApplications.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2573474a",
  null
  
)

export default component.exports