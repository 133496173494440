var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{staticClass:"mt-5",attrs:{"title":"Reports"}}),_c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"shadow border rounded-top"},[_vm._m(0),_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Select Programme Type")]),_c('v-select',{attrs:{"label":"programme_type","options":_vm.programmeTypes},on:{"input":_vm.loadPaymentType},model:{value:(_vm.programmeType),callback:function ($$v) {_vm.programmeType=$$v},expression:"programmeType"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Select payment")]),_c('v-select',{attrs:{"loading":_vm.paymentTypes.loading,"label":"type","options":_vm.paymentTypes.types},scopedSlots:_vm._u([{key:"spinner",fn:function(ref){
var loading = ref.loading;
return [(loading)?_c('div',{staticClass:"vs__spinner",staticStyle:{"border-left-color":"rgba(88, 151, 251, 0.71)"}},[_vm._v(" The .vs__spinner class will hide the text for me. ")]):_vm._e()]}}]),model:{value:(_vm.paymentType),callback:function ($$v) {_vm.paymentType=$$v},expression:"paymentType"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Select Start Date")]),_c('b-form-datepicker',{attrs:{"id":"datepicker-dateformat2","date-format-options":{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    },"locale":"en"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Select End Start")]),_c('b-form-datepicker',{attrs:{"id":"datepicker-dateformat1","date-format-options":{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    },"locale":"en"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_vm._m(1)])])]),_c('div',{staticClass:"col"},[_c('report-table')],1)])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block bg-secondary rounded text-white"},[_c('div',{staticClass:"text-center"},[_c('b',[_vm._v("Filter Reports Here")])]),_c('hr',{staticClass:"w-100 m-0 p-0"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block"},[_c('button',{staticClass:"btn btn-sm btn-outline-info"},[_vm._v("Load")]),_c('button',{staticClass:"btn btn-sm btn-primary rounded float-right"},[_vm._v(" Dowload XLSX ")])])}]

export { render, staticRenderFns }