<template>
    <div v-if="visible" class="spinner-overlay">
      <b-spinner
        style="width: 2rem; height: 2rem;"
        type="grow"
        variant="primary"
      ></b-spinner>
    </div>
  </template>
  
  <script>
  export default {
    computed: {
      visible() {
        return this.$store.state.spinner.globalSpinnerVisible;

      },
    },
  };
  </script>
  
  <style>
  .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  </style>
  