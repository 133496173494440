<template>
  <ul class="app-menu list-unstyled accordion" id="menu-accordion">
    <li class="nav-item">
      <router-link
        class="nav-link"
        :class="activeRoute == 'overview' ? 'active' : ''"
        :to="{ name: 'overview' }"
      >
        <span class="nav-icon">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-house-door"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z"
            />
            <path
              fill-rule="evenodd"
              d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
            />
          </svg>
        </span>
        <span class="nav-link-text">Overview</span>
      </router-link>
    </li>

    <li class="nav-item"  v-if="type == 'staffRole' || type == 'admin'"
    >
      <router-link
        class="nav-link"
      :class="activeRoute == 'approvePgcoord' ? 'active' : ''"
        :to="{ name: 'approvePgcoord' }"
      >
        <span class="nav-icon">
           <b-icon icon="app-indicator" aria-hidden="true"></b-icon>
        </span>
        <span class="nav-link-text">My Department</span>
      </router-link>
    </li>

    <!-- <li class="nav-item">
      <router-link to="/document" class="nav-link">
        <span class="nav-icon">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-folder"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z"
            />
            <path
              fill-rule="evenodd"
              d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z"
            />
          </svg>
        </span>
        <span class="nav-link-text">Docs</span> </router-link
      >
    </li> -->

    <!-- <li class="nav-item">
      <a class="nav-link" href="orders.html">
        <span class="nav-icon">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-card-list"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"
            />
            <path
              fill-rule="evenodd"
              d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"
            />
            <circle cx="3.5" cy="5.5" r=".5" />
            <circle cx="3.5" cy="8" r=".5" />
            <circle cx="3.5" cy="10.5" r=".5" />
          </svg>
        </span>
        <span class="nav-link-text">Orders</span> </a
      >
    </li> -->

    <li
      class="nav-item has-submenu"
      v-if="type == 'admin' "
    >
      <a
        class="nav-link submenu-toggle"
        href="#"
        data-toggle="collapse"
        data-target="#submenu-curriculum"
        aria-expanded="false"
        aria-controls="submenu-curriculum"
      >
        <span class="nav-icon">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-files"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"
            />
            <path
              d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"
            />
          </svg>
        </span>
        <span class="nav-link-text">Course</span>
        <span class="submenu-arrow">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-chevron-down"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </span>
      </a>
      <div
        id="submenu-curriculum"
        class="collapse submenu submenu-1"
        data-parent="#menu-accordion"
      >
        <ul class="submenu-list list-unstyled">
          <li class="submenu-item">
            <router-link class="submenu-link" :to="{ name: 'courses' }"
              >Upload Course</router-link
            >
          </li>
          <!-- <li class="submenu-item">
            <router-link class="submenu-link" to="/account">
              Account</router-link
            >
          </li>
          <li class="submenu-item">
            <router-link class="submenu-link" to="/settings"
              >Settings</router-link
            >
          </li> -->
        </ul>
      </div>
    </li>


    <!-- Adminssion start-->
    <li
      class="nav-item has-submenu"
      v-if="type == 'staffRole' || type == 'admin' || type == 'pgSchool'|| type == 'pgCoord'"
    >
      <a
        class="nav-link submenu-toggle"
        href="#"
        data-toggle="collapse"
        data-target="#submenu-2"
        aria-expanded="false"
        aria-controls="submenu-2"
      >
        <span class="nav-icon">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-files"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"
            />
            <path
              d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"
            />
          </svg>
        </span>
        <span class="nav-link-text">Admission</span>
        <span class="submenu-arrow">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-chevron-down"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </span>
      </a>
      <div
        id="submenu-2"
        class="collapse submenu submenu-1"
        data-parent="#menu-accordion"
      >
        <ul class="submenu-list list-unstyled">
          <li class="submenu-item">
            <router-link class="submenu-link" :to="{ name: 'applicants' }"
              >Applicants</router-link
            >
          </li>
          <!-- <li class="submenu-item">
            <router-link class="submenu-link" to="/account">
              Account</router-link
            >
          </li>
          <li class="submenu-item">
            <router-link class="submenu-link" to="/settings"
              >Settings</router-link
            >
          </li> -->
        </ul>
      </div>
    </li>

    <li
      class="nav-item has-submenu"
      v-if="type == 'accountants' || type == 'admin'"
    >
      <a
        class="nav-link submenu-toggle"
        href="#"
        data-toggle="collapse"
        data-target="#submenu-1"
        aria-expanded="false"
        aria-controls="submenu-1"
      >
        <span class="nav-icon">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-files"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"
            />
            <path
              d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"
            />
          </svg>
        </span>
        <span class="nav-link-text">Payment</span>
        <span class="submenu-arrow">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-chevron-down"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </span>
      </a>
      <div
        id="submenu-1"
        class="collapse submenu submenu-1"
        data-parent="#menu-accordion"
      >
        <ul class="submenu-list list-unstyled">
          <li class="submenu-item">
            <router-link class="submenu-link" :to="{ name: 'Admission' }"
              >Admission</router-link
            >
          </li>
          <li class="submenu-item">
            <router-link class="submenu-link" :to="{ name: 'Student' }">
              Student</router-link
            >
          </li>
          <!--  <li class="submenu-item">
            <router-link class="submenu-link" to="/settings"
              >Settings</router-link
            >
          </li> -->
        </ul>
      </div>
    </li>

    <!--Admission end-->

    <!-- <li class="nav-item has-submenu">
      <a
        class="nav-link submenu-toggle"
        href="#"
        data-toggle="collapse"
        data-target="#submenu-1"
        aria-expanded="false"
        aria-controls="submenu-1"
      >
        <span class="nav-icon">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-files"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"
            />
            <path
              d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"
            />
          </svg>
        </span>
        <span class="nav-link-text">Pages</span>
        <span class="submenu-arrow">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-chevron-down"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg> </span
        > </a
      >
      <div
        id="submenu-1"
        class="collapse submenu submenu-1"
        data-parent="#menu-accordion"
      >
        <ul class="submenu-list list-unstyled">
          <li class="submenu-item">
            <router-link class="submenu-link" to="/notifications"
              >Notifications</router-link
            >
          </li>
          <li class="submenu-item">
            <router-link class="submenu-link" to="/account"
              >Account</router-link
            >
          </li>
          <li class="submenu-item">
            <router-link class="submenu-link" to="/settings"
              >Settings</router-link
            >
          </li>
        </ul>
      </div>
    </li> -->
    <!-- 
    <li class="nav-item has-submenu">
      <a
        class="nav-link submenu-toggle"
        href="#"
        data-toggle="collapse"
        data-target="#submenu-2"
        aria-expanded="false"
        aria-controls="submenu-2"
      >
        <span class="nav-icon">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-columns-gap"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M6 1H1v3h5V1zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 12h-5v3h5v-3zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5zM6 8H1v7h5V8zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H1zm14-6h-5v7h5V1zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-5z"
            />
          </svg>
        </span>
        <span class="nav-link-text">External</span>
        <span class="submenu-arrow">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-chevron-down"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg> </span
        > </a
      >
      <div
        id="submenu-2"
        class="collapse submenu submenu-2"
        data-parent="#menu-accordion"
      >
        <ul class="submenu-list list-unstyled">
          <li class="submenu-item">
            <a class="submenu-link" href="login.html">Login</a>
          </li>
          <li class="submenu-item">
            <a class="submenu-link" href="signup.html">Signup</a>
          </li>
          <li class="submenu-item">
            <a class="submenu-link" href="reset-password.html"
              >Reset password</a
            >
          </li>
          <li class="submenu-item">
            <a class="submenu-link" href="404.html">404 page</a>
          </li>
        </ul>
      </div>
    </li> -->

    <!-- <li class="nav-item">
      <a class="nav-link" href="charts.html">
        <span class="nav-icon">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-bar-chart-line"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z"
            />
          </svg>
        </span>
        <span class="nav-link-text">Charts</span> </a
      >
    </li> -->

    <!-- <li class="nav-item">
      <a class="nav-link" href="help.html">
        <span class="nav-icon">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-question-circle"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            />
            <path
              d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"
            />
          </svg>
        </span>
        <span class="nav-link-text">Help</span> </a
      >
    </li> -->

  </ul>
</template>

<script>
export default {
  data() {
    return {
      staffRoles: ["HOD", "ADMISSIONOFFICER"],
      accountants: ["BURSARY"],
      pgSchool:["COLLEGEOFFICER", "PGSCHOOL"],
      admin: ["SUPERADMIN", "ICT", "ADMIN"], //TODO remove BURSARY
      pgCoord:['PG-COORD'],
      type: "",
    };
  },
  beforeRouteUpdate(to, from, next) {
    console.log(to);
    console.log(from);
    //  console.log(next)
  },
  computed: {
    activeRoute(){
      console.log(this.$router.history.current.name)
      
      return this.$router.history.current.name
    }
  },
  methods: {
    changeActive() {},
    readySideBar() {
      let roles = this.$store.state.user.roles;
      console.log(roles);
      let staffRole = roles.some((item) => this.staffRoles.includes(item));
      let admin = roles.some((item) => this.admin.includes(item));
      let accountants = roles.some((item) => this.accountants.includes(item));
      let pgSchool = roles.some((item) => this.pgSchool.includes(item));
      let pgCoord = roles.some((item) => this.pgCoord.includes(item))
      if (admin) {
        this.type = "admin";
      } else if (staffRole) {
        this.type = "staffRole";
      } else if (accountants) {
        this.type = "accountants";
      } else if(pgSchool){
        this.type ="pgSchool"
      }else if(pgCoord){
        this.type = 'pgCoord'
      }
      else {
        this.type = "no role defined";
      }

      this.$store.dispatch("roleUsed", this.type);
    },
  },
  created() {
    this.readySideBar();
  },
};
</script>
